<template>
    <div>
        <input type="button" value="click" @click="test" />
        <BaiduMap ak="E8f3c743837925ce120eb93417f478db" style="width:100%;height:700px;" @ready="ready" :center="center" :zoom="zoom" :scroll-wheel-zoom="true" @click="test">
            <bm-marker :position="markPoint" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">

            </bm-marker>
        </BaiduMap>
    </div>
</template>
<script>
    import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
    import { BmMarker } from 'vue-baidu-map'
    export default {
        name: "",
        data() {
            return {
                active: true,
                center: { lng: 0, lat: 0 },
                markPoint: { lng: 114.383176, lat: 36.062233 },
                zoom: 8,
                geoc: {}
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            test: function (type ) {
 

                 
               
                console.log(type.point);
                this.geoc.getLocation(type.point, function (rs) {
                    console.log(rs);
                    var addComp = rs.addressComponents;

                    var trainPlace = addComp.province + addComp.city + addComp.district + addComp.street + addComp.streetNumber;

                    var content = trainPlace;// "<br/><br/>经度：" + myPoint.lng + "<br/>纬度：" + myPoint.lat;

                    console.log(content);

                   

                });


            },
            ready: function (BMap) {
                console.log(BMap)
                this.geoc = new BMap.BMap.Geocoder();
                console.log(this.geoc);
            }

        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
            BaiduMap, BmMarker
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成

            //var   map = new BMap.Map('map', { enableMapClick: false });
            //  map.enableScrollWheelZoom();
            //  map.setMapStyle({ style: 'bluish' });


        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>